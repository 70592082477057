import React, { useState } from 'react';

import DesktopApp from './DesktopApp.js';
function App() {
	if (window.screen.width <= 800) {
		window.location.replace('https://duvait.lt/mobile');
	} else {
		return <DesktopApp />;
	}
}

export default App;
